import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/zixundongtai',
    name: 'ZiXunDongTai',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ZiXunDongTai.vue')
  },
  {
    path: '/zixundongtaixiangqing',
    name: 'ZiXunDongTaiXiangQing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ZiXunDongTaiXiangQing.vue')
  },
  {
    path: '/zhuanjialiebiao',
    name: 'ZhuanJiaLieBiao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ZhuanJiaLieBiao.vue')
  },
  {
    path: '/jicengpeixun',
    name: 'JiCengPeiXun.vue',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/JiCengPeiXun.vue')
  },
  {
    path: '/chuangyouxiangmu',
    name: 'ChuangYouXiangMu.vue',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChuangYouXiangMu.vue')
  },
  {
    path: '/tongzhigonggao',
    name: 'TongZhiGongGao.vue',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TongZhiGongGao.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有 savedPosition，则滚动到该位置
    if (savedPosition) {
      return savedPosition;
    }
    // 否则滚动到页面顶部
    return { x: 0, y: 0 };
  },
})

export default router
