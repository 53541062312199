// src/utils/http.js
import axios from 'axios';

// 创建axios实例
const http = axios.create({
    // baseURL: 'https://info-api.whale4cloud.com', // 根据自己的实际API地址进行设置
    timeout: 600000, // 请求超时时间
});

// 请求拦截器
http.interceptors.request.use(
    (config) => {
        // 在请求发送前可以进行一些操作，例如添加token等
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
http.interceptors.response.use(
    (response) => {
        // 对响应数据进行处理，例如解析数据、错误处理等
        return response.data;
    },
    (error) => {
        // 对响应错误进行处理
        return Promise.reject(error);
    }
);

export default http;
