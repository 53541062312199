 <template>
  <div class="footer">
    <div class="footer1">
      <div class="footer2">
        <p class="p1">指&nbsp;&nbsp;&nbsp;&nbsp;导&nbsp;&nbsp;&nbsp;单&nbsp;&nbsp;位：广东省卫生健康委员会</p>
<!--        <p class="p2">-->
<!--          技术支持单位：千百万（广州）医疗科技有限公司-->
<!--        </p>-->
        <p class="p3">
          <a href="https://beian.miit.gov.cn/" style="color: #024395;">粤ICP备2024172134号</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href= "https://beian.mps.gov.cn/#/query/webSearch?code=44010602012173" rel="noreferrer" target="_blank"  style="color: #024395;display: flex;flex-direction: row;">
            <img src="../imag/guohui.jpg" alt="" style="margin-right: 5px;">粤公网安备44010602012173
          </a>
        </p>
        <p class="p3">
          Copyright© 2023 gdbqw.com All rights reserved
        </p>
      </div>
<!--      <div class="vertical-line"></div>-->
      <div class="footer3">
<!--        <div class="footer4">客户服务</div>-->
<!--        <div class="footer5">-->
<!--          <div class="dianhua" style="width: 160px">-->
<!--            <div class="img_1">-->
<!--              <img src="../imag/footer/img_1.png" class="img_2" />-->
<!--            </div>-->
<!--            <p class="p4">020-87011111</p>-->
<!--          </div>-->

<!--          <div class="youxiang">-->
<!--            <div class="img_11">-->
<!--              <img src="../imag/footer/img_2.png" class="img_3" />-->
<!--            </div>-->
<!--            <p class="p5">support@gpwjzx.com</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  margin-top: 20px;
  display: flex;
  width: 100%;
  background: linear-gradient(270deg, #dcdcdc 0%, #f1f1f1 100%);
}
.vertical-line {
  width: 142px;
  height: 0px;
  transform: rotate(90deg);
  opacity: 1;
  border: 1px solid #cfcfcf;
}
.footer1 {
  display: flex;
  margin: 0 auto;
  width: 1440px;
  height: 320px;
  opacity: 1;
  justify-content: space-around;
  align-items: center;
}
.footer2 {
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 132px;
  justify-content: space-around;
}
.footer3 {
  margin: 0px;
  display: flex;
  width: 285px;
  height: 108px;
  flex-direction: column;
  justify-content: space-between;
}
.p1 {
  width: 500px;
  height: 24px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  color: #024395;
  display: flex;
  margin: 0;
}
.p2 {
  width: 500px;
  height: 24px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;

  color: #024395;
  margin: 0px;
  display: flex;
}
.p3 {
  width: 810px;
  height: 20px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

  color: #024395;
  display: flex;
  flex-direction: row;
  margin: 0px;
}
.footer4 {
  display: flex;
  width: 66px;
  height: 20px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

  color: #024395;
}
.footer5 {
  width: 360px;
  height: 80px;
  display: flex;
  justify-content: space-between;
}
.dianhua {
  width: 138px;
  height: 48px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.youxiang {
  width: 175px;
  height: 48px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img_1 {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background-color: #9b1c1c;
  align-items: center;
}
.img_11 {
  margin: 0px;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 25px;
  background-color: #9b1c1c;
  align-items: center;
}
.img_2 {
  display: flex;
  margin: 0 auto;

  width: 14px;
  height: 13px;
}
.img_3 {
  display: flex;
  margin: 0 auto;

  width: 14px;
  height: 11px;
}
.p4 {
  margin: 0px;
  display: flex;
  width: 100px;
  height: 20px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

  color: #024395;
}
.p5 {
  width: 110px;
  height: 20px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
@media screen and (max-width: 1440px) {
  .footer1 {
    width: 1200px;
  }
}
</style>
