<template>
  <div class="headerBack">
    <div class="contentBack">
      <div class="contentBack-1">
        <img src="../imag/img.png" class="imglogo" />
        <p class="p1">卫生健康“百千万工程”管理平台</p>
      </div>
      <div class="contentBack-2">
        <el-link
          @click="goto('/', 0)"
          :class="{ 'is-active': count === 0 }"
          class="link1"
          >首页</el-link
        >
        <el-link
          @click="goto('/zixundongtai', 1)"
          class="link1"
          :class="{ 'is-active': count === 1 }"
          >资讯动态</el-link
        >
        <el-link
          @click="goto('/tongzhigonggao', 2)"
          class="link1"
          :class="{ 'is-active': count === 2 }"
          >通知公告</el-link
        >
        <el-link
          @click="goto('/chuangyouxiangmu', 3)"
          class="link1"
          :class="{ 'is-active': count === 3 }"
          >创优项目</el-link
        >
        <el-link
          @click="goto('/jicengpeixun', 4)"
          class="link1"
          style="width: 148px"
          :class="{ 'is-active': count === 4 }"
          >薪火培基项目</el-link
        >
        <el-button @click="gotoLogin()" class="anniu">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["getCount"]),
  },
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      selected: 10,
      count: 0,
    };
  },
  mounted() {
    // 监听 $route 对象的变化
    this.$watch("$route", (to, from) => {
      // 在路由变化时执行的代码逻辑
      if (to.path == "/") {
        this.setCount(0);
        this.count = 0;
      } else if (to.path == "/zixundongtai") {
        this.setCount(1);
        this.count = 1;
      } else if (to.path == "/tongzhigonggao") {
        this.setCount(2);
        this.count = 2;
      } else if (to.path == "/chuangyouxiangmu") {
        this.setCount(3);
        this.count = 3;
      } else if (to.path == "/jicengpeixun") {
        this.setCount(4);
        this.count = 4;
      }
    });
  },
  methods: {
    ...mapMutations(["setCount"]),
    goto(router, index) {
      this.$router.push(router);
      this.setCount(index);
      this.count = index
      console.log("count", this.getCount);
    },
    gotoLogin() {
      window.open("http://platform.gdbqw.com/#/login", "_blank");
    },
  },
};
</script>

<style scoped>
.is-active {
  /* background-color: blue; */
  color: #024395 !important;
  border-bottom: 2px solid #024395;
}
.headerBack {
  width: 100%;
  height: 400px;
  background: linear-gradient(270deg, #e5f2ff 0%, #b3d5ff 100%);
  display: flex;
}
.contentBack {
  justify-content: space-around;
  align-items: center; /* 子元素纵向居中对齐 */
  width: 1440px;
  height: 100px;
  margin: 0 auto;
  display: flex;
}
.contentBack-1 {
  display: flex;
  width: 556px;
  height: 73px;
  display: flex;
  align-items: center; /* 子元素纵向居中对齐 */
  justify-content: space-between;
}
.contentBack-2 {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 840px;
  height: 51px;
}
.imglogo {
  display: flex;
  width: 72px;
  height: 73px;
}
.p1 {
  display: flex;
  margin: 0px;
  font-family: Source Han Serif CN;
  font-size: 32px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.link1 {
  display: flex;
  width: 96px;
  height: 35px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0em;

  /* 文字/444 */
  color: #444444;
}
.anniu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 42px;
  border-radius: 5px;

  /* 颜色/主色红 */
  background: #b72123;
  color: #f8dfcf;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
}

@media screen and (max-width: 1440px) {
  .contentBack {
    justify-content: space-around;
    align-items: center; /* 子元素纵向居中对齐 */
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
  }
  .p1 {
    font-size: 28px;
  }
}
</style>
