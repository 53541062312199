<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->

    <div class="lunboback">
      <div class="lunboback1">
        <el-carousel height="300px" indicator-position="none" v-if="BannerList.length > 0" arrow="always">
          <el-carousel-item
            v-for="(item, index) in BannerList"
            :key="index + 20"
          >
            <!-- <img :src="item.url" class="img-lunboback" /> -->
            <img :src="item.image" class="img-lunboback" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="zixundongtai">
      <div class="zixundongtai1">
        <div class="zixundongtai2">
          <p class="zixundongtai3">资讯动态 -</p>
        </div>
        <div class="zixundongtai4">
          <el-button class="zixundongtai5" @click="gotoUrl('/zixundongtai')"
            >更多 ></el-button
          >
        </div>
      </div>
      <div class="zixundongtai6">
        <div class="zixundongtai7">
          <el-carousel class="lunboback2" indicator-position="none" arrow="always">
            <el-carousel-item
              v-for="(item, index) in zxdtList"
              :key="index + 100"
            >
              <img v-if="item.cover" :src="item.cover" class="img-lunboback2" />
              <div v-if="item.title.length < 45" class="img-title"  @click="gotoZiXunDetial(item)">{{item.title}}</div>
              <div v-else class="img-title"  @click="gotoZiXunDetial(item)">{{item.title.substring(0,45)}}...</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="zixundongtai8">
          <div
            class="zixundongtai9"
            v-for="(item, index) in zxdtList"
            :key="index + 200"
            @click="gotoZiXunDetial(item)"
          >
            <div class="zixundongtai10">
              {{ item.title }}
              <!--              <el-link :underline="false" class="zixundongtai100">{{-->
              <!--                item.title-->
              <!--              }}</el-link>-->
            </div>
            <div class="zixundongtai11">
              <h class="zixundongtai111">{{ item.createTime.split(" ")[0] }}</h>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="jcpx2">
      <div class="jcpx2-1">
        <div class="zixundongtai2">
          <p class="zixundongtai3">通知公告 -</p>
        </div>
        <div class="zixundongtai4">
          <el-button @click="gotoUrl('/tongzhigonggao')" class="zixundongtai5"
          >更多 ></el-button
          >
        </div>
      </div>
      <div class="jcpx2-2">
        <div
            class="jcpx2-2-1"
            v-for="(item, index) in tzggList"
            :key="index + 15"
            @click="gotoZiXunDetialT(item)"
        >
          <div class="jcpx2-2-2">
            <div class="pp1">
              {{ item.title }}
            </div>
            <!--              <el-link :underline="false" class="pp1">{{item.title}}</el-link>-->
            <p class="pp2">{{ item.createTime.split(" ")[0] }}</p>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="tongzhigonggao">-->
<!--      <div class="zixundongtai1">-->
<!--        <div class="zixundongtai2">-->
<!--          <p class="zixundongtai3">通知公告 -</p>-->
<!--        </div>-->
<!--        <div class="zixundongtai4">-->
<!--          <el-button class="zixundongtai5" @click="gotoUrl('/tongzhigonggao')"-->
<!--            >更多 ></el-button-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="tongzhigonggao1">-->
<!--        <div-->
<!--          class="tongzhigonggao2"-->
<!--          v-for="(item, index) in tzggList"-->
<!--          :key="index + 400"-->
<!--          @click="gotoZiXunDetialT(item)"-->
<!--        >-->
<!--          <div class="tongzhigonggao3">-->
<!--            {{ item.title }}-->
<!--          </div>-->
<!--          <div class="tongzhigonggao4">-->
<!--            <h class="tongzhigonggao4">{{ item.createTime }}</h>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="zhuanjiaku">
      <div class="zixundongtai1">
        <div class="zixundongtai2">
          <p class="zixundongtai3">专家库 -</p>
        </div>
        <div class="zixundongtai4">
          <el-button class="zixundongtai5" @click="gotoUrl('/zhuanjialiebiao')"
            >更多 ></el-button
          >
        </div>
      </div>
      <div class="zhuanjiaku1">
        <div class="zhuanjiaku2">
          <div
            class="zhuanjiakuback"
            v-for="(item, index) in zjkList"
            :key="index + 12"
          >
            <div class="zhuanjiaku4">
              <img :src="item.avatar" class="img_zhuanjia" />
              <div class="zhuanjiaku4-1">
                <p class="p2">{{ item.name }}</p>
                <p class="p3">{{ item.titleName }}</p>
                <p v-if="item.section" class="p3">{{ item.section }}</p>
                <p class="p4">{{ item.dept }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="zhuanjiaku3">
        <div class="zhuanjiaku4" v-for="item in zhuanjia" :key="item.id">

          <img src="../imag/zhuanjia/img.png" class="img_zhuanjia">
          <p class="p2">{{item.name}}</p>
          <p class="p3">{{item.zhiwei}}</p>
          <p class="p4">{{item.zhiwei}}</p>

        </div>
        </div> -->
      </div>
    </div>
    <div class="youqinglianjie">
      <div class="youqinglianjie1">
        <p class="p5">友情链接</p>
      </div>
      <div class="youqinglianjie2">
        <div
          class="youqinglianjie4"
          v-for="(item, index) in yqljList"
          @click="gotLink(item)"
          :key="index + 101"
        >
          <div class="youqinglianjie5">
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import http from "@/utils/http";
import Footer from "@/components/Footer.vue";
export default {
  name: "home",
  components: {
    Footer,
  },
  data() {
    return {
      yqljList: [],

      BannerList: [],
      zjkList: [],
      zxdtList: [
        {
          cover: "",
        },
      ],
      tzggList: [],
      tableData: [
        {
          name: "省卫生健康委组织委直属机关党员干部集中观看中共三大题材话剧《春园·1923》",
          time: "2003-07-03",
          url: "www.baidu.com",
        },
        {
          name: "1",
          time: "2003-07-03",
          url: "www.baidu.com",
        },
      ],
      images: ["src/imag/img_1.png", "image2.jpg", "image3.jpg"],
      currentIndex: 0,
    };
  },
  created() {
    this.fetchData();
    this.zxdtData();
    this.tzggData();
    this.yqljData();
    this.zjkData();
  },
  methods: {
    gotLink(item) {
      window.open(item.url, "_blank");
    },
    fetchData() {
      http
        .get("/api/public/banner/list/0", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.BannerList = response.data;
          console.log("---------", this.BannerList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    zxdtData() {
      http
        .get("/api/public/article/list/1", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zxdtList = response.data;
          console.log("-----资讯动态----", this.zxdtList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    tzggData() {
      http
        .get("/api/public/article/list/2", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.tzggList = response.data;
          console.log("---------", this.tzggList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    yqljData() {
      http
        .get("/api/public/link/list", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.yqljList = response.data;
          console.log("---------", this.yqljList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    zjkData() {
      http
        .get("/api/public/expert/list", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          // 处理响应数据
          this.zjkList = response.data;
          console.log("zjkList", this.zjkList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },

    gotoUrl(router) {
      this.$router.push(router);
    },
    gotoZiXunDetial(item) {
      this.$router.push("/zixundongtaixiangqing?id=" + item.id + "&type=1");
    },
    gotoZiXunDetialT(item) {
      this.$router.push(
        "/zixundongtaixiangqing?id=" + item.id + "&type=1&type2=1"
      );
    },
  },
};
</script>
<style scoped>
.zixundongtai7 {
  ::v-deep .el-carousel__container {
    position: relative;
    height: 464px !important;
  }
}

.home {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.img-title {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 1000px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lunboback {
  width: 1440px;
  height: 300px;
  display: flex;
  margin: 0 auto;
  margin-top: -300px;
}
.lunboback1 {
  width: 100%;
  height: 100%;
}
.img-lunboback {
  width: 100%;
  height: 100%;
  display: flex;
}
zhuanjiaku4-1 {
  display: flex;
  width: 240px;
  height: 100px;
  justify-content: space-around;
  flex-direction: column;
}
.zixundongtai {
  flex-direction: column;
  display: flex;
  width: 1440px;
  height: 523px;
  margin: 0 auto;

  margin-top: 8px;
}
.zixundongtai1 {
  justify-content: space-between;
  display: flex;
  width: 1440px;
  height: 51px;
  background-color: white;
}
.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}
.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zixundongtai5 {
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  padding: 4px 16px;
  /* 文字/666 */
  color: #666666;
}
.zixundongtai6 {
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
  width: 1440px;
  height: 464px;
}
.zixundongtai7 {
  display: flex;
  width: 1000px;
  height: 464px;
}
.zixundongtai8 {
  display: flex;
  width: 416px;
  height: 468px;
  background: #f4f9ff;
  flex-direction: column;
  align-items: center;
}
.lunboback2 {
  width: 1000px;
  height: 464px;
}
.img-lunboback2 {
  display: flex;
  height: 464px;
  width: 1000px;
}
.zixundongtai9 {
  display: flex;
  width: 380px;
  height: 36px;
  margin-top: 12px;
  justify-content: space-between;
  align-items: center;
}
.zixundongtai9:hover {
  background: #d3e7ff;
}
.zixundongtai10 {
  text-align: left;
  width: 350px;
  height: 20px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.zixundongtai10:hover {
  cursor: pointer;
  color: #024395;
}
.zixundongtai100 {
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  /* 文字/666 */
  color: #666666;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.zixundongtai100::after {
  content: "...";
}
.zixundongtai111 {
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  /* 文字/888 */
  color: #888888;

  z-index: 1;
}
.zixundongtai11 {
  width: 150px;
  height: 20px;
  display: flex;
  justify-content: right;

  z-index: 1;
}
.tongzhigonggao {
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  margin-top: 8px;
  width: 1440px;
  min-height: 200px;
  max-height: 533px;
}
.tongzhigonggao1 {
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  width: 1440px;
  max-height: 464px;
  border: 1px solid #d3e7ff !important; /* 设置边框为实线 */
  padding: 16px 0px;
}
.tongzhigonggao2 {
  margin: 0 auto;
  display: flex;
  margin-top: 8px;
  width: 1392px;
  height: 36px;
  align-self: stretch;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
}
.tongzhigonggao2:hover {
  background: #efefef;
}
.tongzhigonggao3 {
  width: 800px;
  height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tongzhigonggao3:hover {
  cursor: pointer;
  color: #024395;
}
.tongzhigonggao4 {
  display: flex;
  width: 150px;
  height: 20px;
  ont-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: right;

  /* 文字/888 */
  color: #888888;
}
.zhuanjiaku {
  width: 1440px;
  height: 955px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}
.zhuanjiaku1 {
  display: flex;
  margin-top: 8px;
  width: 1440px;
  max-height: 896px;
  flex-direction: column;
  /*justify-content: space-between;*/
}
.zhuanjiaku2 {
  display: flex;
  width: 100%;
  max-height: 436px;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
  justify-content: flex-start;

}
.zhuanjiaku3 {
  display: flex;
  width: 1540px;
  height: 436px;
  justify-content: space-around;
}
.zhuanjiaku4 {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #eef6ff;
  padding: 0px;
  justify-items: stretch;
  width: 275px;
  height: 420px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 5px;
}
.zhuanjiakuback {
  margin: 0 6px;
}
.img_zhuanjia {
  margin: 0 auto;
  display: flex;
  width: 275px;
  height: 320px;
}
.p2 {
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: center;
  text-align: center;
  color: #212121;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 6px;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */
}
.p3 {
  text-align: center;
  margin-top: 16px;
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: center;
  color: #3d3d3d;
  margin-bottom: 6px;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */
  z-index: 2;
  margin: 0 auto;
}
.p4 {
  text-align: center;
  justify-content: center;
  width: 265px;
  height: 20px;
  opacity: 1;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  white-space: nowrap; /* 禁止文字换行 */
  overflow: hidden; /* 超出长度时隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出长度时显示省略号 */

  color: #8e8e8e;

  z-index: 3;
  margin: 0 auto;
}
.youqinglianjie {
  width: 100%;
  height: 323px;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(270deg, #e5f2ff 0%, #b3d5ff 100%);
  flex-direction: column;

}
.youqinglianjie1 {
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 83px;
  justify-content: center;
}
.youqinglianjie2 {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  margin: 0 auto;
  /*flex-direction: column;*/
  width: 1440px;
  max-height: 216px;
  /* justify-items: stretch; */
}
.p5 {
  display: flex;
  width: 96px;
  height: 35px;
  opacity: 1;

  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.youqinglianjie4 {
  /* margin: 0 auto;
  width: 1200px;
  height: 36px;
  opacity: 1; */
  /* 自动布局 */
  display: flex;

  /* justify-content: space-around;
  flex-direction: row-reverse; */
}
.youqinglianjie5 {
  width: 172px;
  height: 36px;
  margin-right: 94px;
  margin-left: 94px;
  margin-bottom: 24px;
  display: flex;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid #96b8e1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  color: #024395;
  text-align: center;
  justify-content: center; /* 水平居中 */
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  color: #024395;
  padding: 0 10px;

  .text {
    width: 172px;
    white-space: nowrap; /* 禁止文字换行 */
    overflow: hidden; /* 超出长度时隐藏溢出部分 */
    text-overflow: ellipsis; /* 超出长度时显示省略号 */
  }
}
.youqinglianjie5:hover {
  cursor: pointer;
}
.p6 {
  margin: 0 auto;

  opacity: 1;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}



.jcpx2 {
  margin: 0px auto;
  opacity: 1;
  width: 1440px;
  display: flex;
  flex-direction: column;
}
.jcpx2-1 {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 51px;
  background-color: white;
}
.jcpx2-2 {
  display: flex;
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-around;
  background: #fafafa;
  border-image: linear-gradient(270deg, #dcdcdc 0%, #f1f1f1 100%) 1;
  align-self: stretch;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
}
.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}
.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}
.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jcpx2-2-1 {
  display: flex;
  width: 100%;
  opacity: 1;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #dcdcdc;
}
.jcpx2-2-1:first-child:hover {
  /* 在这里添加首个子元素的 hover 样式 */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.jcpx2-2-1:hover {
  background: #efefef;
}
.jcpx2-2-1:last-child {
  border-bottom: none;
}
.jcpx2-2-1:last-child:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.jcpx2-2-2 {
  align-items: center;
  width: calc(100% - 60px);
  height: 64px;
  opacity: 1;
  justify-content: space-between;
  /* 自动布局 */
  display: flex;
}

@media screen and (max-width: 1400px) {
  .lunboback {
    width: 1200px;
    height: 300px;
    display: flex;
    margin: 0 auto;
    margin-top: -300px;
  }
  .zixundongtai {
    width: 1200px;
  }
  .zixundongtai1 {
    width: 1200px;
  }
  .zixundongtai6 {
    width: 1200px;
  }
  .lunboback2 {
    width: 760px;
  }
  .img-lunboback2 {
    width: 760px;
  }

  .zixundongtai7 {
    display: flex;
    width: 760px;
    height: 464px;
  }

  .jcpx2 {
    width: 1200px;
  }

  .youqinglianjie2 {
    width: 1200px;
  }
  .zhuanjiaku {
    width: 1200px;
    height: 1375px;
  }
  .zhuanjiaku1 {
    max-height: 1375px;
    width: 1200px;
  }
  .zhuanjiakuback {
    margin: 0 12px;
  }
  .img-title {
    width: 760px;
  }
  .youqinglianjie5 {
    margin-right: 64px;
    margin-left: 64px;
  }
}
</style>
