import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        count: 0,
    },
    mutations: {
        setCount(state, value) {
            state.count = value;
        },
    },
    getters: {
        getCount(state) {
            return state.count;
        },
    },
    plugins: [createPersistedState()]
});

export default store;